import UserWrapper from "../UserWrapper";
import DetailedResponse from "../../../DetailedResponse";
import Sidebar from "../../../model/user/viewables/Sidebar";

class GetSidebar extends UserWrapper {
    async request(token: string): Promise<DetailedResponse<Sidebar>> {
        let request = await this.formattedRequest(
            "/viewables/sidebar",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        let viewable = new Sidebar(
            request.additionalInformation.username,
            request.additionalInformation.sidebar
        );
        return new DetailedResponse<Sidebar>(200, request.text, viewable);
    }
}

const getSidebar = new GetSidebar();
export default getSidebar;
