import React, {JSX} from "react";

interface IContainer {
    title: string;
    children: JSX.Element[] | JSX.Element;
}

export const Row = (props: IContainer) => {
    return (
        <section className={"container"}>
            <section className={"row"}>
                <div className={"col-md-3"}>
                    <span className={"row-title"}>{props.title}</span>
                </div>
                {props.children}
            </section>
        </section>
    );
};