import {useParams} from "react-router-dom";
import React, {JSX, useEffect, useState} from "react";
import {Dashboard} from "../../../elements/dashboard/Dashboard";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import GetForm from "../../../backend/wrapper/team/tasks/GetForm";

export const EmbeddedForm = () => {
    const {id} = useParams();

    const [title, setTitle] = useState<JSX.Element>(<i/>);
    const [formAnchor, setFormAnchor] = useState<string>("");

    useEffect(() => {
        let teamCode: string;
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        userCache.teams.then(async (teams) => {
            if (teams === undefined) return;

            //Extract the current team information
            if (sessionStorage.getItem("form-team-name") !== undefined)
                teamCode = teams?.teams?.filter(
                    (value) =>
                        value.companyName === sessionStorage.getItem("form-team-name")
                )[0]?.teamCode;
            if (teamCode === undefined) teamCode = teams?.teams[0]?.teamCode;
            if (teamCode === undefined) return;

            let embeddedForm = await GetForm.request(
                id as string,
                teamCode,
                userCache.token
            );
            if (embeddedForm.status !== 200) return;
            //Extract the current team information

            setTitle(
                <>
                    <i className={"fas fa-clipboard-list"}/>
                    &nbsp;{embeddedForm.additionalInformation.formName}
                </>
            ); //Set the title
            injectResizeWindowScript(); //Inject the script tag

            let formsite = false;
            let embed = embeddedForm.additionalInformation.embed.trim().split("\n");
            if (embed[0].startsWith('<script>')) {
                formsite = false;
                embed.shift();
            } //remove the first line (pre-injected script tag)

            let formAnchor = embed[0];
            setFormAnchor(formAnchor);
            embed.shift();

            if (formsite) {
                let embedManagerScript = embed[0].split('"')[1];

                //Remove the extra elements
                embed.shift();
                embed.shift();
                embed.pop();
                //Remove the extra elements

                let embedManagerRunner = embed.join("");

                injectEmbedManagerScript(embedManagerScript, undefined, embedManagerRunner);
            } else {
                let currentEmbed = embed[0].trim();
                currentEmbed = currentEmbed.substring(32, currentEmbed.length - 10)
                injectEmbedManagerScript(currentEmbed, "text/javascript");
            }
        });
    }, [id]);

    function injectResizeWindowScript() {
        if (document.getElementById("resizeWindowScript")) return;

        //Inject the resizeWindow script
        let resizeWindow = document.createElement("script");
        resizeWindow.innerHTML = `function resizeWindow(){}`;
        resizeWindow.id = "resizeWindowScript";
        document.body.appendChild(resizeWindow);
        //Inject the resizeWindow script
    }

    let loaded = false;

    function injectEmbedManagerScript(
        script: string,
        type?: string,
        embedManagerRunner?: string
    ) {
        let currentEmbedManager = document.getElementById("formsiteEmbedManager");
        if (currentEmbedManager) currentEmbedManager.remove();

        //Inject the embed manager script
        let embedManagerScript = document.createElement("script");
        if (type === "text/javascript") embedManagerScript.innerHTML = script;
        else embedManagerScript.src = script;
        embedManagerScript.id = "formsiteEmbedManager";
        document.body.appendChild(embedManagerScript);

        embedManagerScript.addEventListener("load", (event) => {
            if (loaded) return;
            // eslint-disable-next-line no-new-func
            if (embedManagerRunner) new Function(embedManagerRunner as string)();
            loaded = true;
        });
        //Inject the embed manager script
    }

    return (
        <Dashboard title={title} className={"external-form"}>
            {formAnchor ? (
                <div dangerouslySetInnerHTML={{__html: formAnchor}}/>
            ) : undefined}
        </Dashboard>
    );
};
