import TeamWrapper from "./TeamWrapper";
import GeneralResponse from "../../GeneralResponse";

class UpdateTeamInformation extends TeamWrapper {
    async request(
        code: string,
        name: string,
        mission: string,
        productDescription: string,
        productName: string,
        token: string
    ): Promise<GeneralResponse> {
        let body: any = {code: code};
        if (name !== undefined) body["companyName"] = name;
        if (mission !== undefined) body["mission"] = mission;
        if (productDescription !== undefined)
            body["productDescription"] = productDescription;
        if (productName !== undefined) body["productName"] = productName;
        return await this.formattedRequest("/", "PATCH", token, body);
    }
}

const  updateTeamInformation = new UpdateTeamInformation();
export default updateTeamInformation;
