import AdminWrapper from "../AdminWrapper";
import GeneralResponse from "../../../GeneralResponse";

class CreateArticle extends AdminWrapper {
    async request(name: string, token: string): Promise<GeneralResponse> {
        return await this.formattedRequest(
            `/article/create`,
            "POST",
            token,
            {name: name}
        );
    }

}

const createArticle = new CreateArticle();
export default createArticle;
