import {Dashboard} from "../../../elements/dashboard/Dashboard";
import {JSX, useEffect, useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {FullInformation} from "../../../elements/dashboard/FullInformation";
import {Table} from "../../../elements/table/Table";
import {TableRow} from "../../../elements/table/TableRow";
import {TableData} from "../../../elements/table/TableData";
import HTMLReactParser from "html-react-parser";

export const TeamList = () => {
    const [tables, setTables] = useState<JSX.Element[]>([]);
    const [active, setActive] = useState(0);

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        userCache.supervisorTeamList.then((teamList) => {
            let tables: JSX.Element[] = [];

            teamList?.programmeTeams.forEach((programmeTeam, index) => {
                let programme: string = programmeTeam[0] as unknown as string;
                let teamInformation = programmeTeam[1] as unknown as string[][];

                let head = teamInformation[0];
                let information = [...teamInformation];
                information.shift(); //Remove the team name

                let tableRowElement: JSX.Element[] = [];

                information.forEach((tableRow, index) => {
                    let tableData: JSX.Element[] = [];

                    switch (index) {
                        case 0:
                            tableData.push(<TableData element={"Students"}/>);
                            break;
                        case 1:
                            tableData.push(<TableData element={"Team Code"}/>);
                            break;
                        case 2:
                            tableData.push(<TableData element={"Mission"}/>);
                            break;
                        case 3:
                            tableData.push(<TableData element={"Product Name"}/>);
                            break;
                        case 4:
                            tableData.push(<TableData element={"Product Description"}/>);
                            break;
                        case 5:
                            tableData.push(
                                <TableData element={"Total share capital issued"}/>
                            );
                            break;
                    }

                    tableRow.forEach((tableInformation) => {
                        tableData.push(
                            <TableData
                                element={HTMLReactParser(
                                    tableInformation.replaceAll("\n", "<br/>")
                                )}
                            />
                        );
                    });

                    tableRowElement.push(
                        <TableRow key={Math.random()}>{tableData}</TableRow>
                    );
                });

                tables.push(
                    <FullInformation
                        key={programme}
                        active={index === active}
                        name={programme}
                    >
                        <Table key={programme} download={true} head={["", ...head]}>
                            {tableRowElement}
                        </Table>
                    </FullInformation>
                );

                setTables(tables);
            });
        });
    }, [active]);

    function slickChange(currentSlide: number, nextSlide: number) {
        setActive(nextSlide);
    }

    return (
        <Dashboard
            title={"Team Information"}
            sliderKey={"programme"}
            slickChange={slickChange}
        >
            {tables}
        </Dashboard>
    );
};
