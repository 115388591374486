import TeamWrapper from "../TeamWrapper";
import DetailedResponse from "../../../DetailedResponse";

class GetForm extends TeamWrapper {
    async request(
        id: string,
        code: string,
        token: string
    ): Promise<DetailedResponse<IFormEmbed>> {
        return await this.detailedFormattedRequest<IFormEmbed>(
            `/form/${encodeURIComponent(code)}/${encodeURIComponent(id)}`,
            "GET",
            token,
            undefined
        );
    }
}

interface IFormEmbed {
    formName: string;
    embed: string;
}

const getForm = new GetForm();
export default getForm;
