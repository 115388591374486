import {Dashboard} from "../../../elements/dashboard/Dashboard";
import {JSX, useEffect, useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {FullInformation} from "../../../elements/dashboard/FullInformation";
import {Table} from "../../../elements/table/Table";
import {TableRow} from "../../../elements/table/TableRow";
import {TableData} from "../../../elements/table/TableData";

export const FormList = () => {
    const [tables, setTables] = useState<JSX.Element[]>([]);
    const [active, setActive] = useState(0);

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        userCache.supervisorFormList.then((programmeFormList) => {
            let tables: JSX.Element[] = [];

            programmeFormList?.programmeForms.forEach((programmeTeamForm, index) => {
                let programme: string = programmeTeamForm[0] as unknown as string;
                let teamInformation = programmeTeamForm[1] as unknown as string[][];

                let head = [...teamInformation[0]];
                head.shift();
                let information = [...teamInformation];
                information.shift(); //Remove the team name

                let tableRowElement: JSX.Element[] = [];

                information.forEach((tableRow) => {
                    let tableData: JSX.Element[] = [];

                    tableRow.forEach((tableInformation, index) => {
                        let data: JSX.Element = <></>;

                        if (index === 1) return;
                        if (index === 0) {
                            data = (
                                <>
                  <span className={"table-cell-main-information"}>
                    {tableInformation}
                  </span>
                                    <br/>
                                    <span className={"extra-information"}>
                    {tableRow[index + 1]}
                  </span>
                                </>
                            );
                        } else if (tableInformation === "true")
                            data = <i className={"far fa-check-square checkbox"}/>;
                        else if (tableInformation === "false")
                            data = <i className={"far fa-square checkbox"}/>;
                        tableData.push(<TableData element={data} key={index}/>);
                    });

                    tableRowElement.push(
                        <TableRow key={tableRow[0]}>{tableData}</TableRow>
                    );
                });

                tables.push(
                    <FullInformation
                        key={programme}
                        active={index === active}
                        name={programme}
                    >
                        <Table head={["", ...head]}>{tableRowElement}</Table>
                    </FullInformation>
                );

                setTables(tables);
            });
        });
    }, [active]);

    function slickChange(currentSlide: number, nextSlide: number) {
        setActive(nextSlide);
    }

    return (
        <Dashboard
            title={"Team Information"}
            sliderKey={"programme"}
            slickChange={slickChange}
        >
            {tables}
        </Dashboard>
    );
};
