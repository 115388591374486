import GeneralResponse from "../../GeneralResponse";
import TeamWrapper from "./TeamWrapper";

class CreateTeam extends TeamWrapper {
    async request(
        createTeam: ICreateTeam,
        token: string
    ): Promise<GeneralResponse> {
        return await this.formattedRequest("", "POST", token, createTeam);
    }
}

export interface ICreateTeam {
    team: {
        TeamName: string;
        Mission: string;
        ProductName: string;
        ProductDescription: string;
    };
    students: [
        {
            role: string;
            token: string;
        }
    ];
    mentor: {
        token: string | undefined;
    };
}

const createTeam = new CreateTeam();
export default createTeam
