import AdminWrapper from "./AdminWrapper";
import GeneralResponse from "../../GeneralResponse";

class DeleteUser extends AdminWrapper {
    async request(username: string, token: string): Promise<GeneralResponse> {
        return this.formattedRequest(
            `/delete-user/${encodeURIComponent(username)}`,
            "DELETE",
            token,
            undefined
        );
    }
}

// Assign the instance to a variable with a name
const deleteUser = new DeleteUser();
export default deleteUser;