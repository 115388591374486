import AdminWrapper from "../AdminWrapper";
import GeneralResponse from "../../../GeneralResponse";

class CreateTeacher extends AdminWrapper {
    async request(idCard: string, token: string): Promise<GeneralResponse> {
        return await this.formattedRequest(
            `/create/teacher/${encodeURIComponent(idCard)}`,
            "POST",
            token,
            undefined
        );
    }
}

const createTeacher = new CreateTeacher();
export default createTeacher;
