import TeamWrapper from "../TeamWrapper";
import ProgrammeTeamList from "../../../model/team/supervisor/ProgrammeTeamList";
import DetailedResponse from "../../../DetailedResponse";

class ListTeams extends TeamWrapper {
    async request(token: string): Promise<DetailedResponse<ProgrammeTeamList>> {
        let request = await this.detailedFormattedRequest<string[][][]>(
            "/supervisor/list/teams",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        return new DetailedResponse(
            request.status,
            request.text,
            new ProgrammeTeamList(request.additionalInformation)
        );
    }
}

const listTeams = new ListTeams();
export default listTeams;
