import React, {JSX} from "react";

export interface IGenericBox {
    title: JSX.Element | string | undefined;
    text: JSX.Element | string | undefined;
    image: string | undefined;
}

export const GenericBox = (props: IGenericBox) => {
    return (
        <main id={"small-box"}>
            <h1 style={{paddingBottom: 0}}>{props.title}</h1>
            <div>{props.text}</div>
            <br/>
            <br/>
            <img src={props.image} alt={""}/>
        </main>
    );
};