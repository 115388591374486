import {JSX} from "react";

interface IPhase {
    children: JSX.Element | JSX.Element[];
    title: string;
}

export const Stage = (props: IPhase) => {
    return (
        <section className={"phase"}>
            <h2>{props.title}</h2>
            {props.children}
        </section>
    );
};