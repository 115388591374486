import GeneralResponse from "../../../GeneralResponse";
import TeamWrapper from "../TeamWrapper";

class AssignStudent extends TeamWrapper {
    async request(
        student: string,
        role: string,
        code: string,
        token: string
    ): Promise<GeneralResponse> {
        return await this.formattedRequest("/assign/student", "POST", token, {
            code: code,
            student: student,
            role: role,
        });
    }
}

const assignStudent = new AssignStudent();
export default assignStudent;
