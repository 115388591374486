import UserWrapper from "../UserWrapper";
import ArticleList, {IChapterArticles} from "../../../model/user/content/ArticleList";
import DetailedResponse from "../../../DetailedResponse";

class GetArticleList extends UserWrapper {
    async request(token: string): Promise<DetailedResponse<ArticleList>> {
        let request = await this.detailedFormattedRequest<Map<string, IChapterArticles[]>>(
            "/articles",
            "GET",
            token,
            undefined
        );

        if (request.status !== 200) return request as any;
        let articleList = new ArticleList(request.additionalInformation);
        return new DetailedResponse<ArticleList>(request.status, request.text, articleList);
    }
}

const getArticleList = new GetArticleList();
export default getArticleList;
