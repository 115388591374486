import AdminWrapper from "./AdminWrapper";
import GeneralResponse from "../../GeneralResponse";

class GetLoginAs extends AdminWrapper {
    async request(
        username: string,
        token: string
    ): Promise<GeneralResponse | string> {
        try {
            let request = await this.unformattedRequest(
                `/login-as/${encodeURIComponent(username)}`,
                "POST",
                token,
                undefined
            );

            return request.data["access_token"];
        } catch (error: any) {
            if (error.response?.data?.text)
                return new GeneralResponse(
                    error.response.status,
                    error.response.data.text,
                    error.response.data.additionalInformation
                );
            else {
                console.error("An unknown error has occurred");
                console.error(error);
                return new GeneralResponse(500, "Internal server error", undefined);
            }
        }
    }
}

const getLoginAsInstance = new GetLoginAs();
export default getLoginAsInstance;