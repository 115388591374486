import {Link} from "react-router-dom";
import {Information} from "./Information";
import {JSX} from "react";

interface IColumnButton {
    external: boolean;
    url: string;
    text: string;
    buttonText: string;
}

export const Button = (props: IColumnButton) => {
    let button: JSX.Element;
    if (!props.external) {
        button = (
            <>
                {props.text}
                <Link to={props.url}>
                    <button style={{marginLeft: "30px"}}>{props.buttonText}</button>
                </Link>
            </>
        );
    } else {
        button = (
            <>
                {props.text}
                <a href={props.url}>
                    <button style={{marginLeft: "30px"}}>{props.buttonText}</button>
                </a>
            </>
        );
    }

    return <Information subtitle={button} information={undefined}/>;
};
