import React, {JSX, useEffect} from "react";
import "./sidebar.css";
import isAuthenticated from "../../../helpers/isAuthenticated";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {SidebarItem} from "./SidebarItem";
import {Loading} from "../../../elements/Loading";
import {Link} from "react-router-dom";

export const Sidebar = () => {
    const [sidebar, setSidebar] = React.useState<JSX.Element | undefined>(
        undefined
    );

    useEffect(() => {
        if (!isAuthenticated()) {
            setSidebar(
                <ul className={"nav flex-column mb-2 mt-3"} id={"main-navigation"}>
                    <li key={"home"}>
                        <Link to={"/"}>
                            <i className="far fa-user-circle"/> Sign-in
                        </Link>
                        <Link to={"/register"}>
                            <i className="fa-solid fa-circle-plus"/> Register
                        </Link>
                        <Link to={"/forgot"}>
                            <i className="fa-solid fa-key"/> Forgot Password
                        </Link>
                    </li>
                </ul>
            );
        } else {
            let userCache = TokenUserCache.getUserCache(
                localStorage.getItem("token") as string
            );

            userCache.sidebar.then((items) => {
                if (items === undefined) {
                    localStorage.removeItem("token");
                    window.location.href = "/";
                    return;
                }

                let sidebarItems: JSX.Element[] = [];
                items?.sidebar.forEach((value) => {
                    sidebarItems.push(
                        <SidebarItem
                            name={value.name}
                            icon={value.icon}
                            url={value.url}
                            external={value.external}
                            key={value.name}
                        />
                    );
                });

                setSidebar(
                    <>
                        <section
                            className={"py-4 px-4 mb-1"}
                            id={"user-information-container"}
                        >
                            <div className={"align-content-center"}>
                                <span className={"small"}>Welcome back,</span>
                                <br/>
                                <span className={"bold"}>{items.username}</span>
                            </div>
                        </section>

                        <ul className={"nav flex-column mb-2 mt-3"} id={"main-navigation"}>
                            {sidebarItems}
                        </ul>

                        <ul id="logout-button" className={"nav py-4 px-4"}>
                            <li key={"logout"}>
                                <a href="/logout" className={"text-white"}>
                                    Logout &nbsp;
                                    <i className="fa-solid fa-arrow-right-from-bracket"/>
                                </a>
                            </li>
                        </ul>
                    </>
                );
            });
        }
    }, []);

    function showSidebar() {
        let navigationBar = document.getElementsByTagName("nav")[0];
        if (!navigationBar || !document.getElementById("navigation-button")) return;

        navigationBar.addEventListener("click", (event) => event.stopPropagation());

        document
            .getElementById("navigation-button")
            ?.addEventListener("click", function (event) {
                if (navigationBar.classList.contains("active"))
                    navigationBar.classList.remove("active");
                else navigationBar.classList.add("active");
                event.stopPropagation();
            });

        document.getElementsByTagName("body")[0].addEventListener("click", () => {
            if (navigationBar.classList.contains("active"))
                navigationBar.classList.remove("active");
        });
    }

    return (
        <>
            <nav>
                <section className={"py-3"} id={"logo-banner-container"}>
                    <div className={"media d-flex align-items-center"}>
                        <a
                            href={"https://jamalta.org"}
                            target={"_blank"}
                            rel={"noreferrer"}
                        >
                            <img src={"/image/logo.png"} alt={"logo"} width={"160"}/>
                            <i className={"fa-solid fa-arrow-up-right-from-square"}/>
                        </a>
                    </div>
                    <hr/>
                </section>

                {sidebar === undefined && (
                    <div style={{margin: "35vh 0 0 3.5vw"}}>
                        <Loading/>
                    </div>
                )}

                {sidebar}
            </nav>

            <div id="navigation-button" onClick={showSidebar}>
                <button>
          <span>
            <i className="fas fa-bars"/>
          </span>
                </button>
            </div>
        </>
    );
};
