import React, {JSX, useEffect, useState} from "react";
import {Dashboard} from "../../elements/dashboard/Dashboard";
import TokenUserCache from "../../backend/cache/TokenUserCache";
import {Team} from "./Team";

export const Teams = () => {
    const [containers, setContainers] = useState<JSX.Element[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0);

    function slickChange(currentSlide: number, nextSlide: number) {
        sessionStorage.setItem("slick-tab-teams", String(nextSlide));
        sessionStorage.setItem(
            "teams-team-name",
            containers[nextSlide]?.props.name
        );
        setActiveTab(nextSlide);
    }

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        userCache.teams.then((value) => {
            if (value === undefined) return;
            let containers: JSX.Element[] = [];

            value.teams.forEach((team, index) =>
                containers.push(
                    <Team
                        team={team}
                        active={activeTab === index}
                        name={team.companyName}
                    />
                )
            );
            setContainers(containers);
        });
    }, [activeTab]);

    return (
        <>
            <Dashboard
                title={"Team Profile"}
                className={"team-profile"}
                slickChange={slickChange}
                sliderKey={"teams"}
            >
                {containers}
            </Dashboard>
        </>
    );
};
