import AuthWrapper from "../AuthWrapper";
import GeneralResponse from "../../../GeneralResponse";

class UseForgotToken extends AuthWrapper {
    async request(token: string, password: string): Promise<GeneralResponse> {
        return await this.formattedRequest(
            `/forgot/${encodeURIComponent(token)}`,
            "POST",
            undefined,
            {password: password}
        );
    }
}

const useForgotToken = new UseForgotToken();
export default useForgotToken
