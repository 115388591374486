import {IVideoPlaylist} from "../../../backend/model/user/viewables/VideoPlaylists";
import {Video} from "./Video";
import {JSX, useState} from "react";

export const Playlist = (props: IVideoPlaylist) => {
    const firstVideoEmbed =
        props.videos[0].embed.html !== undefined
            ? props.videos[0].embed.html
            : "There are currently no videos in the playlist.";
    const [hidden, setHidden] = useState(true);

    function onSeeMore() {
        setHidden(!hidden);
    }

    const videos = () => {
        let elements: JSX.Element[] = [];
        for (let i = 1; i < props.videos.length; i += 2) {
            let currentVideos = [props.videos[i], props.videos[i + 1]];

            elements.push(
                <section className={"row"} key={i}>
                    {currentVideos[0]?.name && (
                        <Video
                            key={currentVideos[0].name}
                            name={currentVideos[0].name}
                            embed={currentVideos[0].embed}
                        />
                    )}
                    {currentVideos[1]?.name && (
                        <Video
                            key={currentVideos[1].name}
                            name={currentVideos[1].name}
                            embed={currentVideos[1].embed}
                        />
                    )}
                </section>
            );
        }
        return elements;
    };

    return (
        <>
            <section className={"container"}>
                <section className={"row title"}>
                    <div className={"col"}>
                        <h2>{props.name}</h2>
                    </div>
                    <div className={"col-auto"}>
                        <img src={props.logoUrl} alt={"Sponsor Logo"} width={250}/>
                    </div>
                </section>

                <section className={"row"}>
                    <div className={"col"}>
                        <div
                            className={"embed"}
                            dangerouslySetInnerHTML={{__html: firstVideoEmbed}}
                        />
                    </div>

                    <div className={"col playlist-content"}>
                        <h3>Video Series Topics</h3>
                        <section>
                            <ol>
                                {props.videos.map((value) => (
                                    <li key={value.name}>{value.name}</li>
                                ))}
                            </ol>
                            <button onClick={onSeeMore}>
                                {hidden ? "Show more" : "Show less"}
                            </button>
                        </section>
                    </div>
                </section>

                <div className={"videos " + (hidden ? "" : "active")}>{videos()}</div>
            </section>
        </>
    );
};
