import {IFormResult} from "../../../backend/wrapper/team/tasks/GetFormResults";
import {JSX, useEffect, useState} from "react";
import {FormItem} from "../../form/FormItem";

export const FormResult = (props: IFormResult) => {
    const [container, setContainer] = useState<JSX.Element[]>([]);
    const [, setOnChange] = useState("");

    function isValidHttpUrl(item: string) {
        let url;

        try {
            url = new URL(item);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    useEffect(() => {
        let items: JSX.Element[] = [];
        props.resultItems.forEach((value) => {
            if (value.value === "" || value.value.length < 1) return;

            if (!isValidHttpUrl(value.value))
                items.push(
                    <FormItem
                        id={value.id}
                        label={value.label}
                        inputType={"text"}
                        required={true}
                        onChange={setOnChange}
                        initialValue={value.value}
                        disabled={true}
                    />
                );
            else
                items.push(
                    <div style={{marginBottom: "3vh"}}>
                        <label>{value.label}: </label> &nbsp;
                        <a href={value.value}>
                            <button>Open Document</button>
                        </a>
                    </div>
                );
        });

        setContainer(items);
    }, [props.resultItems]);

    return <section className={"results"}>{container}</section>;
};
