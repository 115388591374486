import {FormItem} from "../../../elements/form/FormItem";
import {JSX, useEffect, useState} from "react";
import {FormPage} from "../../../elements/form/FormPage";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import GetUserExists from "../../../backend/wrapper/user/GetUserExists";
import CreateTeam from "../../../backend/wrapper/team/CreateTeam";

export const RegisterTeam = () => {
    const [page, setPage] = useState(0);
    const [pageElement, setPageElement] = useState<undefined | JSX.Element>(
        undefined
    );

    const [teamName, setTeamName] = useState("");
    const [amountTeamMembers, setAmountTeamMembers] = useState(0);
    const [mentorExists, setMentorExists] = useState("false");

    const [mentorName, setMentorName] = useState("");
    const [mentorToken, setMentorToken] = useState("");

    const [studentName, setStudentName] = useState("");
    const [studentRole, setStudentRole] = useState("");
    const [studentToken, setStudentToken] = useState("");

    const [currentStudentToken, setCurrentStudentToken] = useState("");
    const [currentStudentExists, setCurrentStudentExists] = useState(false);

    const [mission, setMission] = useState("");
    const [productName, setProductName] = useState("");
    const [productDescription, setProductDescription] = useState("");

    const [currentMovementState, setCurrentMovementState] = useState<"next" | "previous">("next");

    const [studentDetails, setStudentDetails] = useState<{ name: string; role: string; token: string }[]>([]);
    const [update, setUpdate] = useState(Math.random);

    function nextPage() {
        setCurrentMovementState("next");
        setPage(page + 1);
    }

    function onRepeat(counter: number) {
        if (page === 3) {
            let tempStudentDetail = studentDetails;
            tempStudentDetail[counter] = {
                name: studentName,
                role: studentRole,
                token: studentToken,
            };
            setStudentDetails(tempStudentDetail);
        }
        setUpdate(Math.random());
    }

    async function submit(): Promise<string | undefined> {
        let studentInformation: any = [];
        studentDetails.forEach((value) => {
            let token = value.token === "true" ? currentStudentToken : value.token;
            studentInformation.push({role: value.role, token: token});
        });

        let request = await CreateTeam.request(
            {
                team: {
                    TeamName: teamName,
                    Mission: mission,
                    ProductName: productName,
                    ProductDescription: productDescription,
                },
                students: studentInformation,
                mentor: {
                    token: mentorExists === "true" ? mentorToken : undefined,
                },
            },
            localStorage.getItem("token") as string
        );

        if (request.status !== 200) return request.text;
        else {
            TokenUserCache.invalidateCache([localStorage.getItem("token") as string]);
            window.location.href = "/";
            return undefined;
        }
    }

    async function validateData(): Promise<string | undefined> {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        switch (page) {
            case 1:
                let programmeMembers = await userCache.programme;
                if (programmeMembers === undefined) return;

                if (teamName.trim() === "") return "Please provide a team name.";
                if (
                    programmeMembers.programme.includes("Company Programme") &&
                    amountTeamMembers < 3
                )
                    return "There needs to be at least 3 people in the team.";
                else if (amountTeamMembers < 1)
                    return "There needs to be at least 1 person in the team.";
                break;

            case 2:
                if (mentorName.trim() === "") return "Please provide a mentor name.";
                if (mentorToken.trim() === "") return "Please provide a mentor token.";

                let request = await GetUserExists.request(mentorToken, userCache.token);
                if (
                    request.status !== 200 ||
                    !request.additionalInformation.includes("Mentor")
                )
                    return "The user provided is not a mentor.";
                break;
            case 3:
                //Make sure the user has provided a name
                if (studentName === "") return "Please provide a student name.";
                if (studentRole === "") return "Please provide a student role.";
                if (studentToken === "") return "Please provide a student token.";
                //Make sure the user has provided a name

                //Make sure no illegal roles are entered
                if (
                    studentRole.trim().toLowerCase() === "teacher" ||
                    studentRole.trim().toLowerCase() === "link teacher" ||
                    studentRole.trim().toLowerCase() === "mentor"
                )
                    return (
                        "A role with the name " +
                        studentRole +
                        " is not allowed.  Please choose another role name."
                    );
                //Make sure no illegal roles are entered

                //Make sure a user is not inputted more than once
                if (
                    studentDetails.filter((value) => value.token === studentToken)
                        .length >= 1
                )
                    return "A student with that token has already been inputted.  Please enter a new student.";

                //Make sure the needed roles are provided
                let programmeStudent = await userCache.programme;
                if (programmeStudent === undefined) return;

                if (programmeStudent.programme.includes("Company Programme")) {
                    if (
                        studentDetails.length === 0 &&
                        studentRole.trim() !== "Managing Director"
                    )
                        return "The first student in a Company Programme team has to have role Managing Director.";
                    else if (
                        studentDetails.length === 1 &&
                        studentRole.trim() !== "Finance Director"
                    )
                        return "The second student in a Company Programme team has to have role Finance Director.";
                    else if (
                        studentDetails.length === 2 &&
                        studentRole.trim() !== "Secretary"
                    )
                        return "The third student in a Company Programme team has to have role Secretary.";
                }
                //Make sure the needed roles are provided

                let requestToken = studentToken;

                if (studentToken === "true") {
                    let student = await userCache.user;
                    if (student === undefined) return "Error getting current user.";
                    requestToken = student.token;
                    setCurrentStudentToken(student.token);
                    setCurrentStudentExists(true);
                }

                //Make sure the token provided is of a student
                let studentTokenRequest = await GetUserExists.request(
                    requestToken,
                    userCache.token
                );
                if (
                    studentTokenRequest.status !== 200 ||
                    !studentTokenRequest.additionalInformation.includes("Student")
                )
                    return "The user provided is not a student.";
                //Make sure the token provided is of a student
                break;
            case 4:
                if (mission === "") return "Please provide a mission.";
                if (productName === "") return "Please provide a product name.";
                if (productDescription === "")
                    return "Please provide a product description.";
                break;
        }

        return undefined;
    }

    useEffect(() => {
        switch (page) {
            case 0:
                setPageElement(
                    <FormPage
                        title={"Team Registration Instructions"}
                        next={nextPage}
                        currentState={currentMovementState}
                        validateData={validateData}
                        key={1}
                    >
                        <br key={0}/>
                        <p key={1}>
                            Dear Team,
                            <br/>
                            Welcome to the team registration section.
                            <br/>
                            <br/>
                            The objective of this portal is for you to have a centralised
                            space for your documentation and track all the submissions and
                            paperwork required by JA Malta.
                            <br/>
                            We hope that this will serve you well.
                            <br/>
                            <br/>
                            If you come across any issues or have any recommendations please
                            get in touch with us on{" "}
                            <a href="mailto:hello@jamalta.org">hello@jamalta.org</a>
                        </p>
                    </FormPage>
                );
                break;
            case 1:
                setPageElement(
                    <FormPage
                        title={"Team Information"}
                        next={nextPage}
                        currentState={currentMovementState}
                        validateData={validateData}
                        key={2}
                    >
                        <FormItem
                            id={"team-name"}
                            label={"Team Name"}
                            inputType={"text"}
                            required={true}
                            onChange={setTeamName}
                            key={"team-name"}
                            initialValue={teamName}
                        />
                        <FormItem
                            id={"amount-team-members"}
                            label={"Total members in team"}
                            inputType={"number"}
                            required={true}
                            onChange={setAmountTeamMembers}
                            key={"amount-team-members"}
                            initialValue={amountTeamMembers}
                        />
                        <FormItem
                            id={"mentor-exists"}
                            label={"Do you have a mentor?"}
                            inputType={"radio"}
                            required={true}
                            onChange={setMentorExists}
                            radio={[
                                {value: "true", label: "Yes"},
                                {value: "false", label: "No"},
                            ]}
                            key={"mentor-exists"}
                            initialValue={"No"}
                        />
                    </FormPage>
                );
                break;
            case 2:
                setPageElement(
                    <FormPage
                        title={"Mentor Information"}
                        next={nextPage}
                        dependent={mentorExists === "true"}
                        currentState={currentMovementState}
                        validateData={validateData}
                        key={3}
                    >
                        <FormItem
                            id={"mentor-name"}
                            label={"Mentor Name"}
                            inputType={"text"}
                            required={true}
                            onChange={setMentorName}
                            key={"mentor-name"}
                            initialValue={mentorName}
                        />
                        <FormItem
                            id={"mentor-token"}
                            label={"Mentor Token"}
                            inputType={"text"}
                            required={true}
                            onChange={setMentorToken}
                            key={"mentor-token"}
                            initialValue={mentorToken}
                        />
                    </FormPage>
                );
                break;
            case 3:
                setPageElement(
                    <FormPage
                        title={"Student"}
                        next={nextPage}
                        repeatAmount={amountTeamMembers}
                        currentState={currentMovementState}
                        validateData={validateData}
                        onRepeat={onRepeat}
                        key={4}
                    >
                        <FormItem
                            id={"student-name"}
                            label={"Student Name"}
                            inputType={"text"}
                            required={true}
                            onChange={setStudentName}
                            initialValue={""}
                            key={`name-${update}`}
                        />
                        <FormItem
                            id={"student-role"}
                            label={"Student Role"}
                            inputType={"text"}
                            required={true}
                            onChange={setStudentRole}
                            initialValue={""}
                            key={`role-${update}`}
                        />
                        {studentToken === "true" && !currentStudentExists ? (
                            <></>
                        ) : (
                            <FormItem
                                id={"student-token"}
                                label={"Student Token"}
                                inputType={"text"}
                                required={true}
                                onChange={setStudentToken}
                                initialValue={""}
                                key={`token-${update}`}
                            />
                        )}
                        {currentStudentExists ? (
                            <></>
                        ) : (
                            <FormItem
                                id={"is-current-student"}
                                label={"Is current student?"}
                                inputType={"radio"}
                                required={true}
                                onChange={setStudentToken}
                                radio={[
                                    {value: "true", label: "Yes"},
                                    {value: "false", label: "No"},
                                ]}
                                key={`current-student-${update}`}
                                initialValue={"No"}
                            />
                        )}
                    </FormPage>
                );
                break;
            case 4:
                setPageElement(
                    <FormPage
                        title={"Team Details"}
                        submit={submit}
                        currentState={currentMovementState}
                        validateData={validateData}
                        key={5}
                    >
                        <FormItem
                            id={"mission"}
                            label={"Team Mission"}
                            inputType={"textarea"}
                            required={true}
                            onChange={setMission}
                            initialValue={mission}
                            key={"mission"}
                        />
                        <FormItem
                            id={"product-name"}
                            label={"Product Name"}
                            inputType={"text"}
                            required={true}
                            onChange={setProductName}
                            initialValue={productName}
                            key={"product-name"}
                        />
                        <FormItem
                            id={"product-description"}
                            label={"Product Description"}
                            inputType={"text"}
                            required={true}
                            onChange={setProductDescription}
                            initialValue={productDescription}
                            key={"product-description"}
                        />
                    </FormPage>
                );
        }
    }, [
        amountTeamMembers,
        currentMovementState,
        mentorExists,
        mentorName,
        mentorToken,
        mission,
        page,
        productDescription,
        productName,
        teamName,
        update,
        studentName,
        studentToken,
        studentRole,
        currentStudentExists,
    ]);

    return (
        <main id={"split-box"}>
            <section id={"left"}>{pageElement}</section>

            <section id={"right"}>
                {page % 2 === 0 ? (
                    <img
                        key={"yellow"}
                        src={"/image/team/rightperson-yellow.svg"}
                        alt={"design-element"}
                    />
                ) : (
                    <img
                        key={"blue"}
                        src={"/image/team/rightperson-blue.svg"}
                        alt={"design-element"}
                    />
                )}
            </section>
        </main>
    );
};
