import UserWrapper from "../UserWrapper";
import GeneralResponse from "../../../GeneralResponse";

class MarkArticleRead extends UserWrapper {
    async request(id: string, token: string): Promise<GeneralResponse> {
        return await this.formattedRequest(
            `/article/${encodeURIComponent(id)}/read`,
            "POST",
            token,
            undefined
        );
    }
}

const markArticleRead = new MarkArticleRead();
export default markArticleRead;