import UserWrapper from "./UserWrapper";
import DetailedResponse from "../../DetailedResponse";
import Programme from "../../model/user/Programme";

class GetUserProgramme extends UserWrapper {
    async request(token: string): Promise<DetailedResponse<Programme>> {
        let request = await this.detailedFormattedRequest<string[]>(
            "/programme",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        return new DetailedResponse<Programme>(
            request.status,
            request.text,
            new Programme(request.additionalInformation as string[])
        );
    }
}

const getUserProgramme = new GetUserProgramme();
export default getUserProgramme;
