import UserWrapper from "../UserWrapper";
import Notification, {INotification,} from "../../../model/user/viewables/Notification";
import DetailedResponse from "../../../DetailedResponse";

class GetNotification extends UserWrapper {
    async request(
        notification: string,
        token: string
    ): Promise<DetailedResponse<Notification>> {
        let request = await this.detailedFormattedRequest<INotification>(
            `/viewables/notification/${encodeURIComponent(notification)}`,
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        let notificationObj = new Notification(request.additionalInformation);
        return new DetailedResponse<Notification>(
            request.status,
            request.text,
            notificationObj
        );
    }
}

const getNotification = new GetNotification();
export default getNotification;
