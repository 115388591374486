import TeamWrapper from "../TeamWrapper";
import TaskList, {IStageTeam} from "../../../model/team/TaskList";
import DetailedResponse from "../../../DetailedResponse";

class GetTaskList extends TeamWrapper {
    async request(token: string): Promise<DetailedResponse<TaskList>> {
        let request = await this.detailedFormattedRequest<IStageTeam[]>(
            "/tasks",
            "GET",
            token,
            undefined
        );
        if (request.status !== 200) return request as any;
        return new DetailedResponse<TaskList>(
            request.status,
            request.text,
            new TaskList(request.additionalInformation)
        );
    }
}

const getTaskList = new GetTaskList();
export default getTaskList;
