import TeamWrapper from "../TeamWrapper";
import GeneralResponse from "../../../GeneralResponse";

class AssignTeamLogo extends TeamWrapper {
    contentType = "multipart/form-data";

    async request(
        image: File,
        code: string,
        token: string
    ): Promise<GeneralResponse> {
        let formData = new FormData();
        formData.append("image", image);

        return await this.formattedRequest(
            `/assign/logo/${encodeURIComponent(code)}`,
            "POST",
            token,
            formData
        );
    }
}

const assignTeamLogo = new AssignTeamLogo();
export default assignTeamLogo;
