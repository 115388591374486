import AdminWrapper from "../AdminWrapper";
import DetailedResponse from "../../../DetailedResponse";
import ArticleList, {IFormattedArticle} from "../../../model/admin/ArticleList";

class GetAllArticles extends AdminWrapper {
    async request(token: string): Promise<DetailedResponse<ArticleList>> {
        let request = await this.detailedFormattedRequest<IFormattedArticle[]>(
            "/articles",
            "GET",
            token,
            undefined);

        if (request.status !== 200) return request as any;

        return new DetailedResponse<ArticleList>(request.status, request.text, new ArticleList(request.additionalInformation))
    }

}

const getAllArticles = new GetAllArticles();
export default getAllArticles;
