import TeamWrapper from "../TeamWrapper";
import DetailedResponse from "../../../DetailedResponse";

class GetFormResults extends TeamWrapper {
    async request(
        id: string,
        code: string,
        token: string
    ): Promise<DetailedResponse<IFormResult[]>> {
        return await this.detailedFormattedRequest<IFormResult[]>(
            `/form/${encodeURIComponent(code)}/${encodeURIComponent(id)}/results`,
            "GET",
            token,
            undefined
        );
    }
}

export interface IFormResult {
    id: string;
    resultItems: {
        id: string;
        position: string;
        label: string;
        value: any;
    }[];
}

const getFormResults = new GetFormResults();
export default getFormResults;
