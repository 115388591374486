import {ITeam as TeamInformation} from "../../backend/model/team/Teams";
import React, {JSX, useEffect, useState} from "react";
import {FullInformation} from "../../elements/dashboard/FullInformation";
import TokenUserCache from "../../backend/cache/TokenUserCache";
import UpdateTeamInformation from "../../backend/wrapper/team/UpdateTeamInformation";
import {Column} from "../../elements/dashboard/container/elements/Column";
import {Information} from "../../elements/dashboard/container/elements/Information";
import {Row} from "../../elements/dashboard/container/Row";
import {Link} from "react-router-dom";

export interface ITeam {
  name: string;
  team: TeamInformation;
  active: boolean;
}

export const Team = (props: ITeam) => {
  let userCache = TokenUserCache.getUserCache(
      localStorage.getItem("token") as string
  );

  const [isStudent, setIsStudent] = useState(false);

  //Edit team information
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [companyName, setCompanyName] = useState(props.team.companyName);
  const [abbreviatedName, setAbbreviatedName] = useState(props.team.abbreviatedName);
  const [companyMission, setCompanyMission] = useState(props.team.mission);
  const [productName, setProductName] = useState(props.team.productName);
  const [productDescription, setProductDescription] = useState(
      props.team.productDescription
  );
  //Edit team information

  const [logo, setLogo] = useState<string | undefined>(undefined);

  const [mentors, setMentors] = useState<JSX.Element[]>([]);
  const [students, setStudents] = useState<JSX.Element[]>([]);
  const [teachers, setTeachers] = useState<JSX.Element[]>([]);

  useEffect(() => {
    userCache.user.then((value) => {
      if (value?.userType === "Student") setIsStudent(true);
    });
  }, [userCache.user]);

  useEffect(() => {
    props.team.logo ? setLogo(props.team.logo) : setLogo("/image/team/default-logo.png");

    //Format Mentors
    let mentors: JSX.Element[] = [];
    props.team.mentorInformation.forEach((mentor) => {
      mentors.push(
          <Column key={mentor.email}>
            <Information
                subtitle={mentor.name}
                information={<a href={`mailto:${mentor.email}`}>{mentor.email}</a>}
            />
          </Column>
      );
    });

    if (isStudent) {
      mentors.push(
          <Column key={"add-mentor"}>
            <Information
                subtitle={
                  <Link to={"assign/mentor"}>
                    <i className={"fas fa-plus-circle"}/> Add a mentor
                  </Link>
                }
                information={""}
            />
          </Column>
      );
    }

    setMentors(mentors);
    //Format Mentors

    //Format students
    let students: JSX.Element[] = [];
    for (let i = 0; i < props.team.studentInformation.length + 1; i += 3) {
      let firstUndefined = true;
      let currentBatch = [
        props.team.studentInformation[i],
        props.team.studentInformation[i + 1],
        props.team.studentInformation[i + 2],
      ];
      let currentInformation: JSX.Element[] = [];

      currentBatch.forEach((currentStudent) => {
        if (currentStudent === undefined && firstUndefined) {
          firstUndefined = false;
          if (isStudent)
            currentInformation.push(
                <Information
                    subtitle={
                      <Link to={"assign/student"}>
                        <i className={"fas fa-plus-circle"}/> Add a student
                      </Link>
                    }
                    information={""}
                />
            );
        } else if (currentStudent === undefined) return;
        else
          currentInformation.push(
              <Information
                  subtitle={`${currentStudent.role}: `}
                  information={currentStudent.contact.name}
                  key={currentStudent.contact.email}
                  studentInformation={<><a href={`mailto:${currentStudent.contact.email}`}>Email: {currentStudent.contact.email}</a><br/><a href={`tel:${currentStudent.contact.mobile}`}>Phone Number: {currentStudent.contact.mobile}</a></>}
              />
          );
      });

      students.push(
          <Column key={students.length}>{currentInformation}</Column>
      );
    }

    setStudents(students);
    //Format students

    //Format Teachers
    let teachers: JSX.Element[] = [];
    props.team.linkTeacherInformation.forEach((teacher) => {
      teachers.push(
          <Column key={teacher.email}>
            <Information
                subtitle={teacher.name}
                information={
                  <a href={`mailto:${teacher.email}`}>{teacher.email}</a>
                }
            />
          </Column>
      );
    });
    setTeachers(teachers);
    //Format Teachers
  }, [
    isStudent,
    props.team.linkTeacherInformation,
    props.team.logo,
    props.team.mentorInformation,
    props.team.studentInformation,
  ]);

  function onCancel() {
    setCompanyName(props.team.abbreviatedName);
    setCompanyMission(props.team.mission);
    setProductName(props.team.productName);
    setProductDescription(props.team.productDescription);
  }

  function onSubmit() {
    setLoading(true);

    let body: any = {};
    if (companyName.trim() !== "") body["companyName"] = companyName;
    if (companyMission.trim() !== "") body["companyMission"] = companyMission;
    if (productName.trim() !== "") body["productName"] = productName;
    if (productDescription.trim() !== "")
      body["productDescription"] = productDescription;

    UpdateTeamInformation.request(
        props.team.teamCode,
        body.companyName,
        body.companyMission,
        body.productDescription,
        body.productName,
        userCache.token
    ).then((response) => {
      if (response.status === 200) {
        setLoading(false);
        setEditMode(false);
        TokenUserCache.invalidateCache([userCache.token]);
      } else {
        alert(
            "An unexpected error happened during update team information: " +
            response.text
        );
        onCancel();
        setEditMode(false);
        setLoading(false);
      }
    });
  }

  return (
      <FullInformation
          key={props.team.teamCode}
          name={props.team.companyName}
          active={props.active}
          editMode={editMode}
          setEditMode={setEditMode}
          loading={loading}
          onCancel={onCancel}
          onSubmit={onSubmit}
      >
        <Information
            subtitle={undefined}
            information={
              <div className={"logo"}>
                <Link to={`/team/assign/logo/${props.team.teamCode}`}>
                  <img src={logo} alt={"Team Logo"}/>
                  <section className={"col-md-8 mx-auto"}>Upload new image</section>
                </Link>
              </div>
            }
        />

        <Row title={"Company Information"} key={"company-information"}>
          <Column
              key={"company-name"}
              setEditMode={setEditMode}
              editMode={editMode}
          >
            <Information
                subtitle={"Company Name"}
                information={abbreviatedName}
                onEditInformation={companyName}
                onChange={(value: string) => {
                  setAbbreviatedName(abbreviatedName.replace(companyName, value));
                  setCompanyName(value);
                }}
                editMode={editMode}
                dataType={"text"}
            />
          </Column>

          <Column
              key={"company-mission"}
              setEditMode={setEditMode}
              editMode={editMode}
          >
            <Information
                subtitle={"Mission"}
                information={companyMission}
                onChange={setCompanyMission}
                editMode={editMode}
                dataType={"textarea"}
            />
          </Column>
        </Row>

        <Row title={"Product Information"} key={"product-information"}>
          <Column
              key={"product-name"}
              setEditMode={setEditMode}
              editMode={editMode}
          >
            <Information
                subtitle={"Product Name"}
                information={productName}
                onChange={setProductName}
                editMode={editMode}
                dataType={"text"}
            />
          </Column>

          <Column
              key={"product-description"}
              setEditMode={setEditMode}
              editMode={editMode}
          >
            <Information
                subtitle={"Product Description"}
                information={productDescription}
                onChange={setProductDescription}
                editMode={editMode}
                dataType={"textarea"}
            />
          </Column>
        </Row>

        <Row title={"Team Information"} key={"team-information"}>
          <Column key={"team-code"}>
            <Information
                subtitle={"Team Code"}
                information={props.team.teamCode}
            />
          </Column>
        </Row>

        <Row title={"Student Information"} key={"student-information"}>
          {students}
        </Row>

        <Row
            title={"Share Registry Information"}
            key={"share-registry-information"}
        >
          <Column key={"share-registry-information"}>
            <Information
                subtitle={"Total Share Capital Issued"}
                information={props.team.totalShareCapitalIssued}
            />
          </Column>
        </Row>

        {props.team.vatFinanceTracker && (
            <Row title={"Finance Paperworks"} key={"vat-finance-tracker"}>
              <Column key={"tracker-information"}>
                <Information
                    subtitle={
                      <a
                          href={props.team.vatFinanceTracker}
                          target={"_blank"}
                          rel={"noreferrer"}
                      >
                        <button>Open</button>
                      </a>
                    }
                    information={undefined}
                />
              </Column>
            </Row>
        )}

        <Row title={"Mentor Information"} key={"mentor-information"}>
          {mentors}
        </Row>

        <Row title={"Teacher Information"} key={"teacher-information"}>
          {teachers}
        </Row>
      </FullInformation>
  );
};
