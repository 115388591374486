import {Dashboard} from "../../../elements/dashboard/Dashboard";
import React, {JSX, useEffect, useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {FullInformation} from "../../../elements/dashboard/FullInformation";
import {Chapter} from "../../../elements/dashboard/content/Chapter";
import {Article} from "../../../elements/dashboard/content/Article";

export const ContentList = () => {
    const [containers, setContainers] = useState<JSX.Element[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(localStorage.getItem("token") as string);

        userCache.articleList.then(programmeArticles => {
            if (programmeArticles === undefined) return;
            let containers: JSX.Element[] = [];

            let index = 0;

            programmeArticles.programmeArticleList.forEach((chapterArticles, key) => {
                let chapters: JSX.Element[] = [];
                chapterArticles.forEach(chapter => {
                    let articles: JSX.Element[] = [];
                    chapter.articles.forEach(article => {
                        articles.push(<Article {...article} key={article.name}/>)
                    });

                    chapters.push(
                        <Chapter
                            title={chapter.name}
                            key={chapter.name}
                            blurred={chapter.blurred}>
                            {articles}
                        </Chapter>);
                });

                containers.push(
                    <FullInformation key={key} active={index === activeTab} name={key}>
                        {chapters}
                    </FullInformation>
                )
                ++index;
            });

            setContainers(containers);
        });

    }, [activeTab])

    function slickChange(currentSlide: number, nextSlide: number) {
        sessionStorage.setItem("slick-tab-content", String(nextSlide));
        sessionStorage.setItem("content-programme", containers[nextSlide]?.props.name);
        setActiveTab(nextSlide);
    }

    return (
        <Dashboard
            title={"E-Content"}
            className={"tasks"}
            slickChange={slickChange}
            sliderKey={"content"}
        >

            {containers}
        </Dashboard>
    )
}