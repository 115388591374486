import {Dashboard} from "../../../elements/dashboard/Dashboard";
import {Table} from "../../../elements/table/Table";
import {TableData} from "../../../elements/table/TableData";
import {TableRow} from "../../../elements/table/TableRow";
import {Link, useNavigate} from "react-router-dom";
import {JSX, useEffect, useState} from "react";
import {IFormattedArticle} from "../../../backend/model/admin/ArticleList";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {FullInformation} from "../../../elements/dashboard/FullInformation";
import DeleteArticle from "../../../backend/wrapper/admin/article/DeleteArticle";
import DuplicateArticle from "../../../backend/wrapper/admin/article/DuplicateArticle";

export const ListArticles = () => {
    const [articleList, setArticleList] = useState<IFormattedArticle[]>([]);
    const [table, setTable] = useState<JSX.Element | undefined>(undefined);

    const navigate = useNavigate();

    function renameArticle(article: IFormattedArticle) {
        navigate(`/admin/article/rename/${encodeURIComponent(article.id)}?oldName=${encodeURIComponent(article.name)}`);
    }

    function editArticle(event: any) {
        navigate(`/admin/article/edit/${event.target.id}`)
    }

    function duplicateArticle(event: any) {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        let id = event.target.id;

        DuplicateArticle.request(id, userCache.token).then(value => {
            console.log(value)
            if (value.status !== 200)
                return alert("An error has occurred.  Please refresh the page.  " + value.text);

            TokenUserCache.invalidateCache([userCache.token]); //Invalidate the cache
            getArticleList(); //Refresh Cache
        })

    }

    function deleteArticle(event: any) {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        let id = event.target.id;

        if (!window.confirm(`You are about to delete the article with id: ${id.toString()}.  THIS IS PERMANENT AND CAN'T BE REVERTED.  Are you sure?`)) return;

        DeleteArticle.request(id, userCache.token).then(value => {
            if (value.status !== 200)
                return alert("An error has occurred during article deletion." + value.text);

            let currentArticle = articleList.findIndex((value) => value.id === id);
            TokenUserCache.invalidateCache([userCache.token]); //Invalidate the cache
            if (currentArticle === -1) getArticleList(); //Just refresh the cache.


            let copyOfArticleList = [...articleList];
            copyOfArticleList.splice(currentArticle, 1);
            setArticleList(copyOfArticleList);
        });
    }

    function getArticleList() {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        userCache.adminArticleList.then(articleList => {
            if (articleList === undefined) return;
            setArticleList(articleList.formattedArticles);
        });
    }

    useEffect(() => {
        getArticleList();
    }, [])

    useEffect(() => {
        if (articleList.length === 0) return;

        let tableInformation: JSX.Element[] = [];
        articleList.forEach(value => {
            tableInformation.push(
                <TableRow key={value.id}>
                    <TableData element={value.id}/>
                    <TableData element={value.name}/>

                    <TableData
                        element={
                            <button id={value.id} onClick={(event) => renameArticle(value)}>
                                <i className={"fa-solid fa-pen-to-square"}/> Rename
                            </button>
                        }
                    />

                    <TableData
                        element={
                            <button id={value.id} onClick={editArticle}>
                                <i className={"fa-sharp fa-solid fa-file-pen"}/> Edit
                            </button>
                        }
                    />


                    <TableData
                        element={
                            <button id={value.id} onClick={duplicateArticle}>
                                <i className={"fa-solid fa-copy"}/> Duplicate
                            </button>
                        }
                    />

                    <TableData
                        element={
                            <button id={value.id} onClick={deleteArticle}>
                                <i className={"fa-solid fa-trash"}/> Delete
                            </button>
                        }
                    />
                </TableRow>
            );
        });

        setTable(
            <Table head={["ID", "Internal Name", "", "", "", ""]}>

                {tableInformation}
            </Table>
        )

    }, [articleList])

    return (
        <Dashboard title={"Article List"} noSlider={true}>

            <Link to={'/admin/article/create'}>
                <button style={{float: "right"}}>
                    <i className="fa-solid fa-circle-plus"></i>
                </button>
            </Link>

            <FullInformation key={"list"} active={true}>
                {table}
            </FullInformation>
        </Dashboard>
    )
}