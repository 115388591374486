import AdminWrapper from "../AdminWrapper";
import GeneralResponse from "../../../GeneralResponse";

class CreateAdmin extends AdminWrapper {
    async request(
        username: string,
        password: string,
        token: string
    ): Promise<GeneralResponse> {
        return await this.formattedRequest("/create/admin", "POST", token, {
            username: username,
            password: password,
        });
    }
}

const createAdmin = new CreateAdmin();
export default createAdmin;
