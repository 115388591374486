import AdminWrapper from "./AdminWrapper";
import GeneralResponse from "../../GeneralResponse";

class ResetYearly extends AdminWrapper {
    async request(
        programmeDetail: string,
        token: string
    ): Promise<GeneralResponse> {
        return await this.formattedRequest(
            `/reset/yearly?programme=${encodeURIComponent(programmeDetail)}`,
            "DELETE",
            token,
            undefined
        );
    }
}

const resetYearly = new ResetYearly();
export default resetYearly;