import {Dashboard} from "../../../elements/dashboard/Dashboard";
import React, {JSX, useEffect, useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {Stage} from "../../../elements/dashboard/tasks/Stage";
import {FullInformation} from "../../../elements/dashboard/FullInformation";
import {Task} from "../../../elements/dashboard/tasks/Task";

export const Tasks = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [containers, setContainers] = useState<JSX.Element[]>([]);

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        userCache.taskList.then((taskList) => {
            if (taskList === undefined) return;

            let containers: JSX.Element[] = [];

            taskList.stageTeams.forEach((team, index) => {
                let stages: JSX.Element[] = [];
                team.stageForm.forEach((stage) => {
                    let forms: JSX.Element[] = [];
                    stage.form.forEach((form) =>
                        forms.push(<Task {...form} key={form.id}/>)
                    );
                    stages.push(
                        <Stage title={stage.stage.name} key={stage.stage.name}>
                            {forms}
                        </Stage>
                    );
                });

                containers.push(
                    <FullInformation
                        key={team.teamName}
                        active={index === activeTab}
                        name={team.teamName}
                    >
                        {stages}
                    </FullInformation>
                );
            });

            setContainers(containers);
        });
    }, [activeTab]);

    function slickChange(currentSlide: number, nextSlide: number) {
        sessionStorage.setItem("slick-tab-forms", String(nextSlide));
        sessionStorage.setItem("form-team-name", containers[nextSlide]?.props.name);
        setActiveTab(nextSlide);
    }

    return (
        <>
            <Dashboard
                title={"Tasks List"}
                className={"tasks"}
                slickChange={slickChange}
                sliderKey={"forms"}
            >
                {containers}
            </Dashboard>
        </>
    );
};
