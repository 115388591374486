import {FormPage} from "../../elements/form/FormPage";
import {FormItem} from "../../elements/form/FormItem";
import {GenericBox} from "../../elements/GenericBox";
import React, {useState} from "react";
import TokenUserCache from "../../backend/cache/TokenUserCache";
import resetCache from "../../backend/wrapper/admin/ResetCache";

export const ResetCache = () => {
    const [salesforce, setSalesforce] = useState("");
    const [article, setArticle] = useState("");
    const [user, setUser] = useState("");
    const [showBox, setShowBox] = useState(false);

    async function validateData(): Promise<string | undefined> {
        if(salesforce === "" || article === "" || user === "") return "Please make sure all items are properly checked.";
        return undefined;
    }

    async function submit(): Promise<string | undefined> {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );
        let request = await resetCache.request(salesforce, user, article, userCache.token);
        if (request.status !== 200) return request.text;

        setShowBox(true);
        TokenUserCache.invalidateCache([userCache.token]);
    }

    return (
        <>
            {!showBox ? (
                <main id={"small-box"} className={"right-blue-border"}>
                    <FormPage
                        title={"Reset Cache"}
                        currentState={"submit"}
                        validateData={validateData}
                        submit={submit}
                    >
                        <FormItem
                            id={"salesforce"}
                            label={"Reset Salesforce Cache"}
                            inputType={"radio"}
                            radio={[
                                {label: "Yes", value: "true"},
                                {label: "No", value: "false"}
                            ]}
                            required={true}
                            onChange={setSalesforce}
                            initialValue={""}
                        />

                        <FormItem
                            id={"user"}
                            label={"Reset User Cache"}
                            inputType={"radio"}
                            radio={[
                                {label: "Yes", value: "true"},
                                {label: "No", value: "false"}
                            ]}
                            required={true}
                            onChange={setUser}
                            initialValue={""}
                        />

                        <FormItem
                            id={"article"}
                            label={"Reset Article Cache"}
                            inputType={"radio"}
                            radio={[
                                {label: "Yes", value: "true"},
                                {label: "No", value: "false"}
                            ]}
                            required={true}
                            onChange={setArticle}
                            initialValue={""}
                        />

                        <br/>

                    </FormPage>
                </main>
            ) : (
                <GenericBox
                    title={"Cache Reset"}
                    text={"Selected cache has been successfully reset."}
                    image={"/image/authentication/registration-complete.svg"}
                />
            )}
        </>
    );
}