import TeamWrapper from "../TeamWrapper";

export interface ICreateShareRegistry{
    certificateNumber: number;
    numberOfShares: number;
    shareholderDate: string;
    shareholderName: string;
    shareholderIDNumber: string;
    shareholderAddress: string;
    chit: File
}

class CreateShareRegistryInformation extends TeamWrapper{
    contentType = "multipart/form-data";

    async request(shareRegistry: ICreateShareRegistry, teamCode: string, token: string){
        let formData = new FormData();
        formData.append("chit", shareRegistry.chit);
        formData.append("certificateNumber", String(shareRegistry.certificateNumber));
        formData.append("numberOfShares", String(shareRegistry.numberOfShares));
        formData.append("shareholderDate", shareRegistry.shareholderDate);
        formData.append("shareholderName", shareRegistry.shareholderName);
        formData.append("shareholderIDNumber", shareRegistry.shareholderIDNumber);
        formData.append("shareholderAddress", shareRegistry.shareholderAddress);

        return await this.formattedRequest(`/shareregistry/create/${encodeURIComponent(teamCode)}`, "POST", token, formData);
    }
}

const createShareRegistryInformation = new CreateShareRegistryInformation();
export default createShareRegistryInformation;
