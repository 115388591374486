import AuthWrapper from "./AuthWrapper";
import GeneralResponse from "../../GeneralResponse";

class CreateAccessToken extends AuthWrapper {
    async request(
        username: string,
        password: string
    ): Promise<string | GeneralResponse> {
        try {
            let request = await this.unformattedRequest("", "POST", undefined, {
                username: username,
                password: password,
            });

            return request.data["access_token"];
        } catch (error: any) {
            if (error.response?.data?.text)
                return new GeneralResponse(
                    error.response.status,
                    error.response.data.text,
                    error.response.data.additionalInformation
                );
            else {
                console.error("An unknown error has occurred");
                console.error(error);
                return new GeneralResponse(500, "Internal server error", undefined);
            }
        }
    }
}

const createAccessToken = new CreateAccessToken();
export default createAccessToken;
