import {IArticle} from "../../../backend/model/user/content/ArticleList";
import {JSX, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export const Article = (props: IArticle) => {
    const [display, setDisplay] = useState(false);
    const [readingTime, setReadingTime] = useState<JSX.Element | undefined>(undefined);
    const [readTickBox, setReadTickBox] = useState<JSX.Element>();
    const [buttonText, setButtonText] = useState<string>("Read Article")
    const navigate = useNavigate();

    function displayDescription() {
        setDisplay(!display);
    }

    function onArticleOpen() {
        if(props.locked) return;
        navigate(`/content/${encodeURIComponent(props.salesforceId)}`)
    }

    useEffect(() => {
        if (props.readingTime === undefined || Number.isNaN(props.readingTime)) {
            setReadingTime(<></>);
            return;
        }
        let readingTimeMinutes = (props.readingTime / 60);

        if (readingTimeMinutes <= 1) setReadingTime(<><i
            className="fa-regular fa-timer fa-1x"/>&nbsp;{readingTimeMinutes} minute</>);
        else setReadingTime(<><i className="fa-regular fa-timer fa-1x"/>&nbsp;{readingTimeMinutes} minutes</>);

    }, [props.readingTime])

    useEffect(() => {
        if(props.locked){
            setReadTickBox(<i className="fa-regular fa-lock"></i>);
            setButtonText("Read the above article to access this article.")
        }else if(!props.locked && props.read){
            setReadTickBox(<i className="fa-solid fa-check"></i>);
        }else if(!props.locked && !props.read){
            setReadTickBox(<i className={"fa-regular fa-square text-white"}/>)
        }

    }, [props.read, props.locked])

    return (
        <section className={"task"}>
            <div className={"information"}>
            <span className={"title"}>
                {readTickBox}&nbsp;
                {props.name}
            </span>

                <div className={"right d-inline"}>

                    <span className={"due-date"}>{readingTime}</span>

                    <button type={"button"} className={"open"} onClick={displayDescription}>
                        <div className={"rotate " + (display ? "rotate-down" : "")}>
                            <i className={"fa-solid fa-circle-chevron-down"}/>
                        </div>
                    </button>

                </div>
            </div>

            <div className={"description " + (display ? "active " : "")}>
                <h3>{props.name}</h3>
                <p>{props.snippet}</p>

                <button type={"button"} className={props.locked ? "locked" : ""} onClick={onArticleOpen}>
                    {buttonText}
                </button>
            </div>
        </section>)
}