import {ICreateShareRegistry} from "./CreateShareRegistryInformation";
import TeamWrapper from "../TeamWrapper";

// @ts-ignore
export interface IUpdateShareRegistry extends ICreateShareRegistry {
    id: string;
    chit: File | undefined;
}

class UpdateShareRegistryInformation extends TeamWrapper {
    contentType = "multipart/form-data";

    async request(shareRegistry: IUpdateShareRegistry, token: string) {
        let formData = new FormData();
        formData.append("id", String(shareRegistry.id));
        formData.append("certificateNumber", String(shareRegistry.certificateNumber));
        formData.append("numberOfShares", String(shareRegistry.numberOfShares));
        formData.append("shareholderDate", shareRegistry.shareholderDate);
        formData.append("shareholderName", shareRegistry.shareholderName);
        formData.append("shareholderIDNumber", shareRegistry.shareholderIDNumber);
        formData.append("shareholderAddress", shareRegistry.shareholderAddress);
        if (shareRegistry.chit) formData.append("chit", shareRegistry.chit);

        return await this.formattedRequest(`/shareregistry/update/${encodeURIComponent(shareRegistry.id)}`, "POST", token, formData);
    }
}

const updateShareRegistryInformation = new UpdateShareRegistryInformation();
export default updateShareRegistryInformation;
