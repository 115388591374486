import TeamWrapper from "../TeamWrapper";
import ShareRegistryInformation, {IShareRegistryInformation,} from "../../../model/team/ShareRegistryInformation";
import DetailedResponse from "../../../DetailedResponse";

class GetShareRegistryInformation extends TeamWrapper {
    async request(
        token: string
    ): Promise<DetailedResponse<ShareRegistryInformation>> {
        let request = await this.detailedFormattedRequest<IShareRegistryInformation[]>("/shareregistry", "GET", token, undefined);
        if (request.status !== 200) return request as any;
        return new DetailedResponse<ShareRegistryInformation>(
            request.status,
            request.text,
            new ShareRegistryInformation(request.additionalInformation)
        );
    }
}

const getShareRegistryInformation = new GetShareRegistryInformation();
export default getShareRegistryInformation;
