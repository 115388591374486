import {Dashboard} from "../../../elements/dashboard/Dashboard";
import {Table} from "../../../elements/table/Table";
import {JSX, useEffect, useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import {TableRow} from "../../../elements/table/TableRow";
import {TableData} from "../../../elements/table/TableData";

export const UserList = () => {
    const [table, setTable] = useState<JSX.Element[] | undefined>(undefined);

    useEffect(() => {
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        userCache.supervisorUserList.then((list) => {
            let tableInformation: JSX.Element[] = [];
            if (list === undefined) {
                setTable([]);
                return;
            }

            list.users.forEach((user) => {
                let portalAccount: JSX.Element = user.portalAccount === true ? (
                    <i className="far fa-check-square text-black-50"/>
                ) : (
                    <a
                        target={"_blank"}
                        rel={"noreferrer"}
                        href={user.portalAccount as string}
                    >
                        <button>Signup URL</button>
                    </a>
                );

                tableInformation.push(
                    <TableRow key={user.idCard}>
                        <TableData element={user.name}/>
                        <TableData element={user.email} url={`mailto:${user.email}`}/>
                        <TableData element={user.idCard}/>
                        <TableData element={portalAccount}/>
                        <TableData
                            element={
                                user.studentInTeam ? (
                                    <i className={"far fa-check-square text-black-50"}/>
                                ) : (
                                    <i className={"far fa-square text-black-50"}/>
                                )
                            }
                        />
                        <TableData
                            element={<button>Withdrawal Form</button>}
                            url={`https://fs4.formsite.com/jaye/sokjfm4x2a/fill?id11=${encodeURIComponent(
                                user.id
                            )}`}
                        />
                    </TableRow>
                );
            });
            setTable(tableInformation);
        });
    }, []);

    return (
        <Dashboard title={"Complete Student Registrations"} noSlider={true}>
            {table ? (
                <>
                    {table.length > 0 ?
                        <>
                            <p>Please note that this list might take up to 8 hours to update.</p>
                            <Table download={true}
                                   head={[
                                       "Full Name",
                                       "Email",
                                       "ID Card Number",
                                       "Created Portal Account",
                                       "Student Registered in Team",
                                       "Withdraw from programme",
                                   ]}
                            >
                                {table}
                            </Table>
                        </> : <p>There are no finished student registrations</p>}
                </>
            ) : undefined}
        </Dashboard>
    );
};
