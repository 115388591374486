import {Dashboard} from "../../../elements/dashboard/Dashboard";
import {JSX, useEffect, useState} from "react";
import TokenUserCache from "../../../backend/cache/TokenUserCache";
import getFormResults, {IFormResult,} from "../../../backend/wrapper/team/tasks/GetFormResults";
import {useParams} from "react-router-dom";
import {FullInformation} from "../../../elements/dashboard/FullInformation";
import {FormResult} from "../../../elements/dashboard/tasks/FormResult";

export const FormResults = () => {
    const {id} = useParams();
    const [formResult, setFormResult] = useState<IFormResult[]>([]);

    const [activeTab, setActiveTab] = useState<number>(0);
    const [containers, setContainers] = useState<JSX.Element[]>([]);

    useEffect(() => {
        let teamCode: string;
        let userCache = TokenUserCache.getUserCache(
            localStorage.getItem("token") as string
        );

        userCache.teams.then(async (teams) => {
            if (teams === undefined) return;

            //Extract the current team information
            if (sessionStorage.getItem("form-team-name") !== undefined)
                teamCode = teams?.teams?.filter(
                    (value) =>
                        value.companyName === sessionStorage.getItem("form-team-name")
                )[0]?.teamCode;
            if (teamCode === undefined) teamCode = teams?.teams[0]?.teamCode;
            if (teamCode === undefined) return;
            //Extract the current team information

            let formResult = await getFormResults.request(
                id as string,
                teamCode,
                userCache.token
            );
            if (formResult.status !== 200) return;
            else setFormResult(formResult.additionalInformation);
        });
    }, [id]);

    useEffect(() => {
        if (formResult.length < 1) return;

        let containers: JSX.Element[] = [];
        formResult.forEach((result, index) => {
            containers.push(
                <FullInformation
                    key={result.id}
                    active={activeTab === index}
                    name={`Result: ${index + 1}`}
                >
                    <FormResult {...result} />
                </FullInformation>
            );
        });

        setContainers(containers);
    }, [activeTab, containers, formResult]);

    function slickChange(currentSlide: number, nextSlide: number) {
        setActiveTab(nextSlide);
    }

    return (
        <Dashboard
            title={"Copy of your Team Submissions"}
            slickChange={slickChange}
            sliderKey={"form-result"}
        >
            {containers}
        </Dashboard>
    );
};
